import 'vite/modulepreload-polyfill'

window.registerPush = function() {
  window.addEventListener('visibilityChange', reloadOnAppResume)
  if (window.webkit) {
    window.webkit.messageHandlers.registerPush.postMessage('')
  } else {
    Android.registerPush()
  }
}

window.openSettings = function() {
  window.addEventListener('visibilityChange', reloadOnAppResume)
  if (window.webkit) {
    window.webkit.messageHandlers.openSettings.postMessage('')
  } else {
    Android.showAppSettings()
  }
}

window.reloadOnAppResume = function() {
  if (document.visibilityState !== 'hidden') {
    setTimeout(() => {
      window.location.reload()
    }, 1)
  }
}
